import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

// Hooks
import useSiteMetadata from '../../../hooks/useSiteMetadataQuery'

// Icons
import favicon from '../../../../static/assets/icon.png'

const Seo = (props) => {
  const { site: { siteMetadata }, allPrismicGlobal } = useSiteMetadata()

  // Pull and reformat relevant data from `allPrismicGlobal`
  const cmsMetadata = {
    description: allPrismicGlobal?.nodes[0]?.data?.sitewide_meta_description?.text,
    image: allPrismicGlobal?.nodes[0]?.data?.sitewide_social_image?.url
  }

  /**
   * Filter any items which are empty/null/undefined
   * @param  {Object)
   * @return {Object}
   */
  const definedProps = (obj) => Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => {
      // Only return keys which have a value
      return !!v
    })
  )

  // Merge all of the data, destructuring into variables
  const {
    title,
    customTitle,
    siteName,
    description,
    image,
    robots,
    siteUrl,
    slug
  } = Object.assign({}, siteMetadata, definedProps(cmsMetadata), definedProps(props))

  // Generate a full URL to the content & image path
  const url = slug ? (siteUrl + slug) : siteUrl
  const imageUrl = image || `${siteUrl}/assets/icon.png`

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      function gtag(){
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date());
      gtag('config', 'UA-70748265-2');
    }
  }, [])

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      /* If a customTitle is passed, don't use the `titleTemplate`, instead use
      the customTitle passed as a prop */
      title={customTitle || ''}
      titleTemplate={customTitle ? '' : `%s | ${siteName}`}
      defaultTitle={siteName}
    >
      <title>{customTitle || title}</title>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-70748265-2"></script>

      {/* Resource Hints */}
      {['https://forms.hsforms.com', 'https://js.hsforms.net'].map(resource => <link key={resource} rel='preconnect dns-prefetch' href={resource} />)}

      {/* Trim it if it's too long */}
      <meta name='description' content={description.slice(0, 300)} />

      {/* Robots tag, if a prop is passed */}
      {robots && <meta name='robots' content={robots} />}

      {/* Canonical URL, use the custom one if it's passed as a prop */}
      <link rel='canonical' href={props.customCanonicalUrl || url} />

      {/* Open Graph Tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={customTitle || (title ? `${title} | ${siteName}` : siteName)} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={imageUrl} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:creator' content='@northcoders' />
      <meta name="google-site-verification" content="dxNeUTEhxsP-Xgr5_ynKVOljc25862yBQe4domH5qQ8" />

      <meta name='facebook-domain-verification' content='6r8ii8y58923jjzyvl4l6hj6j3anhv' />

      {/* Favicon */}
      <link rel='shortcut icon' type='image/gif' href={favicon} />

    </Helmet>
  )
}

Seo.propTypes = {
  customCanonicalUrl: PropTypes.string,
  customTitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  isHomepage: PropTypes.bool,
  robots: PropTypes.string,
  siteName: PropTypes.string,
  siteUrl: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string
}

export default Seo
