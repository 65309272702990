import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const siteData = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            siteName
            siteUrl
            description
          }
        }
        allPrismicGlobal {
          nodes {
            data {
              sitewide_meta_description {
                text
              }
              sitewide_social_image {
                url
              }
            }
          }
        }
      }
    `
  )

  return siteData
}

export default useSiteMetadata
