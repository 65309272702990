import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql } from 'gatsby'

/**
 * Update the className for the image
 * @param  {Object} props
 * @return {String}
 */
const getImageClassName = (props) => {
  const defaultClassName = 'w-full'

  if (props.className) {
    // By default, images will be the full width of their container. In the
    // event that we don't want that, remove the `w-full` className as it might
    // override the width className passed oin the props
    if (props.className.includes('w-')) {
      return classNames(defaultClassName.replace('w-full', ''), props.className)
    }

    // If not, just merge the classnames as normal
    return classNames(defaultClassName, props.className)
  }

  // And if none of the above, just return the default
  return defaultClassName
}

const Image = (props) => {
  // Return early if we're missing important props
  if (!props.url || !props.dimensions.width) {
    return null
  }

  // All the common props which will be used across both lazy and non-lazy
  // image elements
  const otherProps = {
    width: parseInt(props.dimensions.width / 2),
    height: parseInt(props.dimensions.height / 2),
    alt: props.alt || props.fallbackAlt,
    intrinsicsize: `${parseInt(props.dimensions.width / 2)}x${parseInt(props.dimensions.height / 2)}`,
    // fallback to native lazyloading, just to be safe
    loading: 'lazy'
  }

  const className = getImageClassName(props)

  if (props.lazyload && props?.fluid?.base64) {
    return (
      <div
        className={classNames(className, 'c-lazyload')}
      >
        <img
          src={props.fluid.base64}
          className={classNames('c-lazyload__img lazyload', props.lazyImageClassName)}
          data-src={props?.fluid?.src || props.url}
          data-srcset={props?.fluid?.srcSet}
          data-sizes='auto'
          {...otherProps}
        />
        <img
          src={props.fluid.base64}
          className={classNames('c-lazyload__preview', props.lazyImageClassName)}
          {...otherProps}
          alt={`Preview: ${otherProps.alt}`}
        />
      </div>
    )
  }

  return (
    <img
      src={props?.fluid?.src || props.url}
      className={className}
      srcSet={props?.fluid?.srcSet}
      sizes={props.sizes?.join(', ')}
      {...otherProps}
    />
  )
}

Image.propTypes = {
  className: PropTypes.string,
  dimensions: PropTypes.object,
  fallbackAlt: PropTypes.string.isRequired,
  fluid: PropTypes.object,
  lazyload: PropTypes.bool,
  lazyImageClassName: PropTypes.string,
  sizes: PropTypes.array,
  url: PropTypes.string
}

Image.defaultProps = {
  lazyload: true
}

export default Image

export const query = graphql`
  fragment Image on PrismicImageType {
    alt
    dimensions {
      height
      width
    }
    url
    # Some sane image sizes, 840 is the widest image on the site
    fluid(srcSetBreakpoints: [48, 100, 200, 300, 400, 500, 600, 700, 800, 840]) {
      base64
      src
      srcSet
    }
  }
  fragment ImageThumbnail on PrismicImageThumbnailType {
    alt
    dimensions {
      height
      width
    }
    url
    # Some sane image sizes, 840 is the widest image on the site
    fluid(srcSetBreakpoints: [48, 100, 200, 300, 400, 500, 600, 700, 800, 840]) {
      base64
      src
      srcSet
    }
  }
`
