import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Generic
import BasicPage from '../components/shared/BasicPage/BasicPage'
import HtmlContent from '../components/shared/HtmlContent/HtmlContent'
import Button from '../components/shared/Button/Button'
import Image from '../components/shared/Image/Image'

// Local (kind of)
import FooterLink from '../components/layout/Footer/FooterLink'

const ApplicationConfirmationPage = (props) => {
  const socialLinks = props.data.socialLinks.data.body.find(item => item.slice_type === 'footer_social')

  useEffect(() => {
    // Facebook Pixel Web Action Event
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'SubmitApplication')
      }
    }
  }, [])

  return (
    <>
      <Seo
        title={props.data.applicationConfirmation.data.title.text}
        robots='noindex'
      />
      <BasicPage>
        <div className='text-center'>
          {props.data.applicationConfirmation.data.image.url && (
            <div className='max-w-sm mx-auto mb-10'>
              <Image
                fallbackAlt={props.data.applicationConfirmation.data.title.text}
                {...props.data.applicationConfirmation.data.image}
              />
            </div>
          )}
          <h1 className='h2 mb-4'>{props.data.applicationConfirmation.data.title.text}</h1>
          <HtmlContent
            html={props.data.applicationConfirmation.data.body.html}
            className='mb-8 c-prose'
          />
          <div className='flex justify-center items-center flex-wrap mb-12'>
            {props.data.applicationConfirmation.data.ctas.map((cta, ctaIndex) => {
              return (
                <Button
                  key={ctaIndex}
                  className='mx-2 mb-4'
                  color={cta.cta_style}
                  icon
                  {...cta.cta_link}
                >
                  {cta.cta_label}
                </Button>
              )
            })}
          </div>
          <p className='text-center uppercase text-primary text-xs tracking-wide font-medium mb-5'>Follow Us</p>
          <ul className='flex items-center justify-center space-x-4'>
            {socialLinks.items.map((link, linkIndex) => {
              return (
                <li key={linkIndex}>
                  <FooterLink
                    type={socialLinks.slice_type}
                    className='text-black'
                    {...link}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </BasicPage>
    </>
  )
}

ApplicationConfirmationPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ApplicationConfirmationPage

export const PageQuery = graphql`
  query ApplicationConfirmationPage {
    applicationConfirmation: prismicApplicationConfirmation {
      data {
        title {
          text
        }
        body {
          html
        }
        image {
          ...Image
        }
        ctas {
          cta_label
          cta_link {
            ...Link
          }
          cta_style
        }
      }
    }
    socialLinks: prismicFooterNavigation {
      data {
        body {
          ... on PrismicFooterNavigationBodyFooterSocial {
            slice_type
            items {
              subitem_platform
              subitem_link {
                ...Link
              }
            }
          }
        }
      }
    }
  }
`
